import React, { useEffect } from 'react';
import '../styles/main.css';
import img1 from '../assets/work1.png';
import img2 from '../assets/work2.png';
import img3 from '../assets/work3.png';
import img4 from '../assets/work4.png';
import ReferNowSection from './dashboardComponents/ReferNowSection';
import Backbtn from '../assets/backbtn.png';
import mixpanel from 'mixpanel-browser';
import { useNavigate } from 'react-router-dom';

const HowItWorks = () => {
  const navigate = useNavigate();

  // const refernowsection = () => {
  //   const mixpanelUserId = localStorage.getItem("userIdMixpanel");
  //   mixpanel.identify(mixpanelUserId);
  //   mixpanel.track("Interacted_PERP_How_It_Works", {
  //     "Clicked on": "Refer Now ppppppp",
  //   });
  // };

  const handleback = () => {
    const mixpanelUserId = localStorage.getItem("userIdMixpanel");
    mixpanel.identify(mixpanelUserId);
    mixpanel.track("Interacted_PERP_How_It_Works", {
      "Clicked on": "Back",
    });
    navigate('/');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const mixpanelUserId = localStorage.getItem("userIdMixpanel");
    if (mixpanelUserId) {
      mixpanel.identify(mixpanelUserId);
      mixpanel.track("Viewed_PERP_How_It_Works", {});
    }
  }, []);

  return (
    <div className="top-sections">
      <button onClick={handleback} className="back-btn mt-30">
        <img src={Backbtn} width={42} height={42} />
      </button>
      <h1>How it Works?</h1>
      <div className="how-it-works-info">
        <div className="referral-container">
          <div className="section">
            <div className="text-content">
              <p>
                Refer a friend by clicking <span className="highlight"> 'Refer Now'</span> and earn{' '}
                <span className="highlight">PayMe Coins</span> that can be converted to cash.
              </p>
            </div>
            <div className="icon">
              <img src={img1} alt="Refer Now" />
            </div>
          </div>
          <div className="gradient-bg"></div>

          <div className="section">
            <div className="icon">
              <img src={img2} alt="Loan Approved" />
            </div>
            <div className="text-content">
              <p>
                If your friend's loan is approved, you both earn <span className="highlight">150 coins</span> each.
              </p>
            </div>
          </div>
          <div className="gradient-bg"></div>

          <div className="section">
            <div className="text-content">
              <p>
                When their loan is disbursed, you both earn <span className="highlight">400 coins</span> each.
              </p>
            </div>
            <div className="icon">
              <img src={img3} alt="Loan Disbursed" />
            </div>
          </div>
          <div className="gradient-bg"></div>

          <div className="section">
            <div className="icon">
              <img src={img4} alt="Convert Coins to Cash" />
            </div>
            <div className="text-content">
              <p>
                PayMe Coins can be converted to cash and credited to your account.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div 
      // onClick={refernowsection} 
      className="refer-now-section">
        <ReferNowSection refer={true} />
      </div>
    </div>
  );
};

export default HowItWorks;
